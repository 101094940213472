// USER
export const FETCH_USER = 'FETCH_USER'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_ITEM_USER = 'RECEIVE_ITEM_USER'
export const RESET_ITEM_USER = 'RESET_ITEM_USER'
export const SUCCESS_USER = 'SUCCESS_USER'
export const UPDATE_STATE_USER = 'UPDATE_STATE_USER'
export const FAILED_USER = 'FAILED_USER'

// STORE
export const FETCH_STORE = 'FETCH_STORE'
export const RECEIVE_STORE = 'RECEIVE_STORE'
export const RECEIVE_CLOSEST_STORE = 'RECEIVE_CLOSEST_STORE'
export const RECEIVE_OPERATIONAL_HOUR_STORE = 'RECEIVE_OPERATIONAL_HOUR_STORE'
export const SET_CURRENT_STORE = 'SET_CURRENT_STORE'
export const UPDATE_STATE_STORE = 'UPDATE_STATE_STORE'
export const FAILED_STORE = 'FAILED_STORE'

// AUTH
export const FETCH_AUTH = 'FETCH_AUTH'
export const RECEIVE_ITEM_AUTH = 'RECEIVE_ITEM_AUTH'
export const FAILED_AUTH = 'FAILED_AUTH'
export const UPDATE_ITEM_AUTH = 'UPDATE_ITEM_AUTH'

// IMAGE
export const FETCH_IMAGE = 'FETCH_IMAGE'
export const SUCCESS_IMAGE = 'SUCCESS_IMAGE'
export const FAILED_IMAGE = 'FAILED_IMAGE'

// SEARCH
export const FETCH_SEARCH = 'FETCH_SEARCH'
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH'
export const RECEIVE_ITEM_SEARCH = 'RECEIVE_ITEM_SEARCH'
export const FAILED_SEARCH = 'FAILED_SEARCH'
export const UPDATE_STATE_SEARCH = 'UPDATE_STATE_SEARCH'

// LOGIN
export const RESET_LOGIN = 'RESET_LOGIN'
export const FETCH_LOGIN = 'FETCH_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const FAILED_LOGIN = 'FAILED_LOGIN'

// REGISTER
export const FETCH_REGISTER = 'FETCH_REGISTER'
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER'
export const FAILED_REGISTER = 'FAILED_REGISTER'

// CHANGE PASSWORD
export const FETCH_CHANGE_PASSWORD = 'FETCH_CHANGE_PASSWORD'
export const RECEIVE_ITEM_CHANGE_PASSWORD = 'RECEIVE_ITEM_CHANGE_PASSWORD'
export const FAILED_CHANGE_PASSWORD = 'FAILED_CHANGE_PASSWORD'

// VERIFICATION
export const FETCH_VERIFICATION = 'FETCH_VERIFICATION'
export const SUCCESS_VERIFICATION = 'SUCCESS_VERIFICATION'
export const FAILED_VERIFICATION = 'FAILED_VERIFICATION'

// RESET PASSWORD
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD'
export const SUCCESS_RESET_PASSWORD = 'SUCCESS_RESET_PASSWORD'
export const FAILED_RESET_PASSWORD = 'FAILED_RESET_PASSWORD'

// LOGIN VERIFICATION
export const FETCH_LOGIN_VERIFICATION = 'FETCH_LOGIN_VERIFICATION'
export const RECEIVE_LOGIN_VERIFICATION = 'RECEIVE_LOGIN_VERIFICATION'
export const FAILED_LOGIN_VERIFICATION = 'FAILED_LOGIN_VERIFICATION'

// REGISTER VERIFICATION
export const FETCH_REGISTER_VERIFICATION = 'FETCH_REGISTER_VERIFICATION'
export const RECEIVE_REGISTER_VERIFICATION = 'RECEIVE_REGISTER_VERIFICATION'
export const FAILED_REGISTER_VERIFICATION = 'FAILED_REGISTER_VERIFICATION'

// RESEND VERIFICATION
export const FETCH_RESEND_VERIFICATION = 'FETCH_RESEND_VERIFICATION'
export const RECEIVE_RESEND_VERIFICATION = 'RECEIVE_RESEND_VERIFICATION'
export const FAILED_RESEND_VERIFICATION = 'FAILED_RESEND_VERIFICATION'

// HOME_EXPLORE_STORE
export const FETCH_HOME_EXPLORE_STORE = 'FETCH_HOME_EXPLORE_STORE'
export const RECEIVE_HOME_EXPLORE_STORE = 'RECEIVE_HOME_EXPLORE_STORE'
export const FAILED_HOME_EXPLORE_STORE = 'FAILED_HOME_EXPLORE_STORE'

// HOME_WEEKLY_TREND
export const FETCH_HOME_WEEKLY_TREND = 'FETCH_HOME_WEEKLY_TREND'
export const RECEIVE_HOME_WEEKLY_TREND = 'RECEIVE_HOME_WEEKLY_TREND'
export const EDIT_QUANTITY_WEEKLY_TREND = 'EDIT_QUANTITY_WEEKLY_TREND'
export const FAILED_HOME_WEEKLY_TREND = 'FAILED_HOME_WEEKLY_TREND'

// HOME_BRAND_LIST
export const FETCH_HOME_BRAND_LIST = 'FETCH_HOME_BRAND_LIST'
export const RECEIVE_HOME_BRAND_LIST = 'RECEIVE_HOME_BRAND_LIST'
export const FAILED_HOME_BRAND_LIST = 'FAILED_HOME_BRAND_LIST'

// HOME_PRODUCT_SIGNATURE
export const FETCH_HOME_PRODUCT_SIGNATURE = 'FETCH_HOME_PRODUCT_SIGNATURE'
export const RECEIVE_HOME_PRODUCT_SIGNATURE = 'RECEIVE_HOME_PRODUCT_SIGNATURE'
export const EDIT_QUANTITY_PRODUCT_SIGNATURE = 'EDIT_QUANTITY_PRODUCT_SIGNATURE'
export const UPDATE_STATE_PRODUCT_SIGNATURE = 'UPDATE_STATE_PRODUCT_SIGNATURE'
export const FAILED_HOME_PRODUCT_SIGNATURE = 'FAILED_HOME_PRODUCT_SIGNATURE'

// SHOPPING_CART
export const FETCH_SHOPPING_CART = 'FETCH_SHOPPING_CART'
export const RECEIVE_SHOPPING_CART = 'RECEIVE_SHOPPING_CART'
export const RECEIVE_SHOPPING_CART_MEMO = 'RECEIVE_SHOPPING_CART_MEMO'
export const RECEIVE_SHOPPING_CART_ID = 'RECEIVE_SHOPPING_CART_ID'
export const FAILED_SHOPPING_CART = 'FAILED_SHOPPING_CART'
export const CHANGE_CURRENT_PARAM_SHOPPING_CART = 'CHANGE_CURRENT_PARAM_SHOPPING_CART'
export const ADD_SHOPPING_CART = 'ADD_SHOPPING_CART'
export const ADD_ITEM_SHOPPING_CART = 'ADD_ITEM_SHOPPING_CART'
export const PRODUCT_DETAIL_SHOPPING_CART = 'PRODUCT_DETAIL_SHOPPING_CART'
export const UPDATE_QUANTITY_SHOPPING_CART = 'UPDATE_QUANTITY_SHOPPING_CART'
export const EDIT_QUANTITY_SHOPPING_CART = 'EDIT_QUANTITY_SHOPPING_CART'
export const UPDATE_STATE_SHOPPING_CART = 'UPDATE_STATE_SHOPPING_CART'
export const COUNT_SUB_TOTAL_SHOPPING_CART = 'COUNT_SUB_TOTAL_SHOPPING_CART'

// STORE_MAP
export const FETCH_STORE_MAP = 'FETCH_STORE_MAP'
export const RECEIVE_STORE_MAP = 'RECEIVE_STORE_MAP'
export const ADD_STORE_MAP = 'ADD_STORE_MAP'
export const UPDATE_STORE_MAP = 'UPDATE_STORE_MAP'
export const SET_DINE_IN_STATUS = 'SET_DINE_IN_STATUS'
export const FAILED_STORE_MAP = 'FAILED_STORE_MAP'

// HOME_CATEGORY_LIST
export const FETCH_HOME_CATEGORY_LIST = 'FETCH_HOME_CATEGORY_LIST'
export const UPDATE_STATE_HOME_CATEGORY_LIST = 'UPDATE_STATE_HOME_CATEGORY_LIST'
export const RECEIVE_HOME_CATEGORY_LIST = 'RECEIVE_HOME_CATEGORY_LIST'
export const FAILED_HOME_CATEGORY_LIST = 'FAILED_HOME_CATEGORY_LIST'

// HOME_BUY_AGAIN
export const FETCH_HOME_BUY_AGAIN = 'FETCH_HOME_BUY_AGAIN'
export const RECEIVE_HOME_BUY_AGAIN = 'RECEIVE_HOME_BUY_AGAIN'
export const EDIT_QUANTITY_BUY_AGAIN = 'EDIT_QUANTITY_BUY_AGAIN'
export const FAILED_HOME_BUY_AGAIN = 'FAILED_HOME_BUY_AGAIN'

// HOME
export const FETCH_HOME = 'FETCH_HOME'
export const RECEIVE_HOME = 'RECEIVE_HOME'
export const RECEIVE_LIST_HOME = 'RECEIVE_LIST_HOME'
export const FAILED_HOME = 'FAILED_HOME'
export const ADD_LIST_HOME = 'ADD_LIST_HOME'
export const UPDATE_STATE_HOME = 'UPDATE_STATE_HOME'

// ANNOUNCEMENT
export const FETCH_ANNOUNCEMENT = 'FETCH_ANNOUNCEMENT'
export const RECEIVE_ANNOUNCEMENT = 'FAILED_ANNOUNCEMENT'
export const FAILED_ANNOUNCEMENT = 'FAILED_ANNOUNCEMENT'

// SELECT_ADDRESS
export const SUCCESS_SELECT_ADDRESS = 'SUCCESS_SELECT_ADDRESS'
export const FETCH_SELECT_ADDRESS = 'FETCH_SELECT_ADDRESS'
export const RECEIVE_SELECT_ADDRESS = 'RECEIVE_SELECT_ADDRESS'
export const RECEIVE_DESCRIPTION_RAW_SELECT_ADDRESS = 'RECEIVE_DESCRIPTION_RAW_SELECT_ADDRESS'
export const RECEIVE_ITEM_SELECT_ADDRESS = 'RECEIVE_ITEM_SELECT_ADDRESS'
export const RECEIVE_ADDRESS_SUGGESTION_LIST = 'RECEIVE_ADDRESS_SUGGESTION_LIST'
export const RECEIVE_ADDRESS_DETAIL = 'RECEIVE_ADDRESS_DETAIL'
export const UPDATE_LATLNG_ITEM_SELECT_ADDRESS = 'UPDATE_LATLNG_ITEM_SELECT_ADDRESS'
export const CLEAR_ITEM_SELECT_ADDRESS = 'CLEAR_ITEM_SELECT_ADDRESS'
export const UPDATE_STATE_SELECT_ADDRESS = 'UPDATE_STATE_SELECT_ADDRESS'
export const UPDATE_STATE_ADDRESS_SUGGESTION = 'UPDATE_STATE_ADDRESS_SUGGESTION'
export const SET_CURRENT_LNGLAT_SELECT_ADDRESS = 'SET_CURRENT_LNGLAT_SELECT_ADDRESS'
export const RESET_STATE_SELECT_ADDRESS = 'RESET_STATE_SELECT_ADDRESS'
export const FAILED_SELECT_ADDRESS = 'FAILED_SELECT_ADDRESS'
export const SET_MAIN_ADDRESS = 'SET_MAIN_ADDRESS'
export const GET_DINE_IN_ADDRESS = 'GET_DINE_IN_ADDRESS'
export const ADD_DINE_IN_ADDRESS = 'ADD_DINE_IN_ADDRESS'
export const UPDATE_DINE_IN_ADDRESS = 'UPDATE_DINE_IN_ADDRESS'

// PRODUCT
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT'
export const RECEIVE_SEARCH_PRODUCT = 'RECEIVE_SEARCH_PRODUCT'
export const EDIT_QTY_SEARCH_PRODUCT = 'EDIT_QTY_SEARCH_PRODUCT'
export const RECEIVE_ITEM_PRODUCT = 'RECEIVE_ITEM_PRODUCT'
export const CLEAN_CURRENT_ITEM_PRODUCT = 'CLEAN_CURRENT_ITEM_PRODUCT'
export const EDIT_QUANTITY_PRODUCT = 'EDIT_QUANTITY_PRODUCT'
export const UPDATE_STATE_PRODUCT = 'UPDATE_STATE_PRODUCT'
export const FAILED_PRODUCT = 'FAILED_PRODUCT'

// USERFAV
export const FETCH_USERFAV = 'FETCH_USERFAV'
export const UPDATE_STATE_USERFAV = 'UPDATE_STATE_USERFAV'
export const RECEIVE_LIST_USERFAV = 'RECEIVE_LIST_USERFAV'
export const RECEIVE_CURRENT_ITEM_USERFAV = 'RECEIVE_CURRENT_ITEM_USERFAV'
export const EDIT_QUANTITY_ITEM_USERFAV = 'EDIT_QUANTITY_ITEM_USERFAV'
export const FAILED_USERFAV = 'FAILED_USERFAV'
export const SUCCESS_SET_FAVOURITE = 'SUCCESS_SET_FAVOURITE'

// ORDER
export const SUCCESS_ORDER = 'SUCCESS_ORDER'
export const FETCH_ORDER = 'FETCH_ORDER'
export const RECEIVE_ORDER = 'RECEIVE_ORDER'
export const RECEIVE_ORDER_LOG = 'RECEIVE_ORDER_LOG'
export const RECEIVE_ITEM_ORDER = 'RECEIVE_ITEM_ORDER'
export const RECEIVE_ITEM_ORDER_DETAIL = 'RECEIVE_ITEM_ORDER_DETAIL'
export const FAILED_ORDER = 'FAILED_ORDER'
export const UPDATE_STATE_ORDER = 'UPDATE_STATE_ORDER'

// USER PROFILE
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE'
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE'
export const FAILED_USER_PROFILE = 'FAILED_USER_PROFILE'
export const UPDATE_STATE_USER_PROFILE = 'UPDATE_STATE_USER_PROFILE'

// WALLET
export const FETCH_WALLET = 'FETCH_WALLET'
export const RECEIVE_WALLET = 'RECEIVE_WALLET'
export const FAILED_WALLET = 'FAILED_WALLET'
export const UPDATE_STATE_WALLET = 'UPDATE_STATE_WALLET'

// CATEGORY
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY'
export const FAILED_CATEGORY = 'FAILED_CATEGORY'
export const UPDATE_STATE_CATEGORY = 'UPDATE_STATE_CATEGORY'
