import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import changePasswordStore from './auth/changePasswordStore'
import userProfileStore from './user-profile/userProfileStore'
import orderStore from './order/orderStore'
import userStore from './userStore'
import loginStore from './auth/loginStore'
import authStore from './auth/authStore'
import resetPasswordStore from './auth/resetPasswordStore'
import searchStore from './search/searchStore'
import registerStore from './auth/registerStore'
import registerVerificationStore from './auth/registerVerificationStore'
import resendVerificationStore from './auth/resendVerificationStore'
import verificationStore from './auth/verificationStore'
import homeStore from './home/homeStore'
import homeExploreStore from './home/homeExploreStore'
import homeAnnouncement from './home/homeAnnouncement'
import homeWeeklyTrend from './home/homeWeeklyTrend'
import shoppingCartStore from './shoppingCart/shoppingCartStore'
import storeStore from './store/storeStore'
import userFavStore from './product/userFavStore'
import selectAddressStore from './selectaddress/selectAddressStore'
import storeMapStore from './storemap/storeMapStore'
import homeBuyAgain from './home/homeBuyAgain'
import homeBrandList from './home/homeBrandList'
import homeCategoryList from './home/homeCategoryList'
import homeProductSignature from './home/homeProductSignature'
import walletStore from './wallet/walletStore'

import categoryStore from './category/categoryStore'

import productStore from './product/productStore'

export default combineReducers({
  form: formReducer,
  userProfileStore,
  changePasswordStore,
  userStore,
  orderStore,
  authStore,
  loginStore,
  resetPasswordStore,
  registerStore,
  registerVerificationStore,
  resendVerificationStore,
  verificationStore,

  homeStore,
  homeExploreStore,
  homeWeeklyTrend,
  shoppingCartStore,
  storeStore,
  userFavStore,
  selectAddressStore,
  storeMapStore,
  homeBuyAgain,
  homeAnnouncement,
  homeBrandList,
  homeCategoryList,
  homeProductSignature,

  walletStore,
  categoryStore,

  searchStore,
  productStore
})
